.pagination {
    list-style: none;
    height: 31.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    cursor: pointer;
    color: #7E7E7E;
}

.pagination li a {
    text-decoration: none;
    color: #7E7E7E;
}
  
.active {
color: #000;
}

.page-item {
list-style: none;
padding: 2px 12px;
height: 31.5px;
width: 31.5px;
display: flex;
justify-content: center;
align-items: center;
margin-top: 2px;
}